import React, { useContext, useState } from 'react';
import { ServiceContext } from '../service-center/ServiceState';

const AddMenu = ({isAdmin}) => {

    const { addLunchMenu } = useContext(ServiceContext);


    function addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toJSON().slice(0, 10);
    }

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);

    const currentDate = new Date().toJSON().slice(0, 10);
    const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });

    const nextSun = new Date();
    nextSun.setDate(nextSun.getDate() + (0 + 7 - nextSun.getDay()) % 7);
    const sundayCheck = nextSun.toJSON().slice(0, 10);

    const d = new Date();
    d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
    const nextMon = d.toJSON().slice(0, 10);
    
    const mday = new Date();
    mday.setDate(mday.getDate() - (mday.getDay() + 6) % 7);
    const preMon = mday.toJSON().slice(0, 10);

    const date = (currentDate === sundayCheck) ? nextMon : preMon;

    const [monday, setMon] = useState({});
    const { mon, dishMon1, dishMon2, dishMon3, dishMon4, dishMon5, dishMon6, dishMon7 } = monday;
    monday.mon = date;

    const [tuesday, setTue] = useState({});
    const { tue, dishTue1, dishTue2, dishTue3, dishTue4, dishTue5, dishTue6, dishTue7 } = tuesday;
    tuesday.tue = addDays(date, 1);

    const [wednesday, setWed] = useState({});
    const { wed, dishWed1, dishWed2, dishWed3, dishWed4, dishWed5, dishWed6, dishWed7 } = wednesday;
    wednesday.wed = addDays(date, 2);

    const [thursday, setThu] = useState({});
    const { thu, dishThu1, dishThu2, dishThu3, dishThu4, dishThu5, dishThu6, dishThu7 } = thursday;
    thursday.thu = addDays(date, 3);
    
    const [friday, setFri] = useState({});
    const { fri, dishFri1, dishFri2, dishFri3, dishFri4, dishFri5, dishFri6, dishFri7 } = friday;
    friday.fri = addDays(date, 4);

    const [saturday, setSat] = useState({});
    const { sat, dishSat1, dishSat2, dishSat3, dishSat4, dishSat5, dishSat6, dishSat7 } = saturday;
    saturday.sat = addDays(date, 5);

    const onSubmit = e => {
        e.preventDefault();

        const newMenu = {
            id: Math.floor(Math.random() * 100000000),
            monLunchMenu: monday,
            tueLunchMenu: tuesday,
            wedLunchMenu: wednesday,
            thuLunchMenu: thursday,
            friLunchMenu: friday,
            satLunchMenu: saturday,
            userOrg: userObj.org
        }

        addLunchMenu(newMenu);
        alert("Menu saved!");

    }

  return (
    <div style={{display: 'block', width: '90vw'}}>
        <details style={{ cursor: 'pointer' }}>
            <summary style={{ listStyleType: 'none' }}>
                <h2 style={{ width: '100%'}}>
                    <b style={{fontSize: '2rem'}}>&#10634;</b>
                    &nbsp;&nbsp;&nbsp; Add &nbsp;Lunch &nbsp;Menu &nbsp;for &nbsp;<b style={{ color: '#FFC107'}}>{userObj.org}</b>
                </h2>
            </summary>
            <h3 style={{justifyContent: 'center'}}>Today is {currentDay}, {currentDate}</h3>
            <form onSubmit={onSubmit}>
                <div style={{display: 'inline-flex', width: '100%', marginLeft: "2rem"}}>
                    <div className='lunch-form-control'>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='date' style={{padding: '1rem 0'}}><b>Mon</b></label>
                            <input type='date' id='date' defaultValue={monday.mon} onClick={(e) => e.preventDefault()}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>1</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={monday.dishMon1} onChange={(e) => setMon({...monday, dishMon1: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>2</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={monday.dishMon2} onChange={(e) => setMon({...monday, dishMon2: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>3</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={monday.dishMon3} onChange={(e) => setMon({...monday, dishMon3: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>4</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={monday.dishMon4} onChange={(e) => setMon({...monday, dishMon4: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>5</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={monday.dishMon5} onChange={(e) => setMon({...monday, dishMon5: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>6</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={monday.dishMon6} onChange={(e) => setMon({...monday, dishMon6: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>7</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={monday.dishMon7} onChange={(e) => setMon({...monday, dishMon7: e.target.value})}/>
                        </div>
                    </div>
                    <div className='lunch-form-control'>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='date' style={{padding: '1rem 0'}}><b>Tue</b></label>
                            <input type='date' id='date' defaultValue={tuesday.tue} onClick={(e) => e.preventDefault()}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>1</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={tuesday.dishTue1} onChange={(e) => setTue({...tuesday, dishTue1: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>2</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={tuesday.dishTue2} onChange={(e) => setTue({...tuesday, dishTue2: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>3</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={tuesday.dishTue3} onChange={(e) => setTue({...tuesday, dishTue3: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>4</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={tuesday.dishTue4} onChange={(e) => setTue({...tuesday, dishTue4: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>5</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={tuesday.dishTue5} onChange={(e) => setTue({...tuesday, dishTue5: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>6</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={tuesday.dishTue6} onChange={(e) => setTue({...tuesday, dishTue6: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>7</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={tuesday.dishTue7} onChange={(e) => setTue({...tuesday, dishTue7: e.target.value})}/>
                        </div>
                    </div>
                    <div className='lunch-form-control'>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='date' style={{padding: '1rem 0'}}><b>Wed</b></label>
                            <input type='date' id='date' defaultValue={wednesday.wed} onClick={(e) => e.preventDefault()}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>1</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={wednesday.dishWed1} onChange={(e) => setWed({...wednesday, dishWed1: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>2</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={wednesday.dishWed2} onChange={(e) => setWed({...wednesday, dishWed2: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>3</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={wednesday.dishWed3} onChange={(e) => setWed({...wednesday, dishWed3: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>4</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={wednesday.dishWed4} onChange={(e) => setWed({...wednesday, dishWed4: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>5</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={wednesday.dishWed5} onChange={(e) => setWed({...wednesday, dishWed5: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>6</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={wednesday.dishWed6} onChange={(e) => setWed({...wednesday, dishWed6: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>7</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={wednesday.dishWed7} onChange={(e) => setWed({...wednesday, dishWed7: e.target.value})}/>
                        </div>
                    </div>
                    <div className='lunch-form-control'>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='date' style={{padding: '1rem 0'}}><b>Thu</b></label>
                            <input type='date' id='date' defaultValue={thursday.thu} onClick={(e) => e.preventDefault()}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>1</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={thursday.dishThu1} onChange={(e) => setThu({...thursday, dishThu1: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>2</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={thursday.dishThu2} onChange={(e) => setThu({...thursday, dishThu2: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>3</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={thursday.dishThu3} onChange={(e) => setThu({...thursday, dishThu3: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>4</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={thursday.dishThu4} onChange={(e) => setThu({...thursday, dishThu4: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>5</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={thursday.dishThu5} onChange={(e) => setThu({...thursday, dishThu5: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>6</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={thursday.dishThu6} onChange={(e) => setThu({...thursday, dishThu6: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>7</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={thursday.dishThu7} onChange={(e) => setThu({...thursday, dishThu7: e.target.value})}/>
                        </div>
                    </div>
                    <div className='lunch-form-control'>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='date' style={{padding: '1rem 0'}}><b>Fri</b></label>
                            <input type='date' id='date' defaultValue={friday.fri} onClick={(e) => e.preventDefault()}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>1</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={friday.dishFri1} onChange={(e) => setFri({...friday, dishFri1: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>2</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={friday.dishFri2} onChange={(e) => setFri({...friday, dishFri2: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>3</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={friday.dishFri3} onChange={(e) => setFri({...friday, dishFri3: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>4</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={friday.dishFri4} onChange={(e) => setFri({...friday, dishFri4: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>5</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={friday.dishFri5} onChange={(e) => setFri({...friday, dishFri5: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>6</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={friday.dishFri6} onChange={(e) => setFri({...friday, dishFri6: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>7</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={friday.dishFri7} onChange={(e) => setFri({...friday, dishFri7: e.target.value})}/>
                        </div>
                    </div>
                    <div className='lunch-form-control'>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='date' style={{padding: '1rem 0'}}><b>Sat</b></label>
                            <input type='date' id='date' defaultValue={saturday.sat} onClick={(e) => e.preventDefault()}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>1</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={saturday.dishSat1} onChange={(e) => setSat({...saturday, dishSat1: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>2</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={saturday.dishSat2} onChange={(e) => setSat({...saturday, dishSat2: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>3</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={saturday.dishSat3} onChange={(e) => setSat({...saturday, dishSat3: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>4</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={saturday.dishSat4} onChange={(e) => setSat({...saturday, dishSat4: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>5</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={saturday.dishSat5} onChange={(e) => setSat({...saturday, dishSat5: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>6</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={saturday.dishSat6} onChange={(e) => setSat({...saturday, dishSat6: e.target.value})}/>
                        </div>
                        <div className='lunch-form-control-small'>
                            <label htmlFor='text'><b>7</b></label>
                            <input type='text' id='text' placeholder='Enter dish...' value={saturday.dishSat7} onChange={(e) => setSat({...saturday, dishSat7: e.target.value})}/>
                        </div>
                    </div>
                </div>
                <button className='lunch-btn' type='submit'>Save</button>
            </form>
        </details>
    </div>
  )
}

export default AddMenu
