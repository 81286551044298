import React, { createContext, useReducer } from 'react';
import TaskReducer from './TaskReducer';
import axios from 'axios';

const initialState = {
    toDos: [],
    error: null,
    loading: true
};

export const TaskContext = createContext(initialState);

export const TaskProvider = ({ children }) => {
    const [state, dispatch] = useReducer(TaskReducer, initialState);

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const token = `${userObj.email}+${userObj.password}`

    async function getToDos() {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            const res = await axios.get('/api/v1/todos', config);

            dispatch({
                type: 'GET_TODOS',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'TODOS_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function addToDo(toDo) {   
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/v1/todos', toDo, config);

            dispatch({
                type: 'ADD_TODO',
                payload: res.data.data
            });

        } catch (err) {
            dispatch({
                type: 'TODOS_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function editToDo(id, toDo) {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/v1/todos/${id}`, toDo, config);
            dispatch({
                type: 'EDIT_TODO',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'TODOS_ERROR',
                payload: err.response.data.error
            });
        }
    }

    return (<TaskContext.Provider value={{
        toDos: state.toDos,
        getToDos,
        addToDo,
        editToDo
    }}>
        {children}
    </TaskContext.Provider>)
}