import React from 'react';
import '../App.css';

const Loader = () => {
  return (
    <div className='page'>
        <div className='loader'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
        </div>
    </div>

  )
}

export default Loader
