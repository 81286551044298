import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalState';

const UserList = () => {
    const { users, getUsers } = useContext(GlobalContext);

    useEffect(() => {
        // getUsers();
        // eslint-disable-next-line
    }, []);

    console.log(users);
    
  return (
    <>
        <h1>This is a list</h1>
    </>
  )
}

export default UserList
