import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ExpenseTrackerApp from "./components/expense-tracker/ExpenseTrackerApp";
import PersonalExpenseTrackerApp from "./components/expense-tracker-personal/PersonalExpenseTracker";
import Login from './components/user-management/Login';
import Signup from './components/user-management/Signup';
import PinBoard from './components/PinBoard';
import NewsPocket from './components/NewsPocket';
import LunchAdmin from './components/lunch-order/LunchAdmin';
import LunchOrder from './components/lunch-order/LunchOrder';
import ServiceTicket from './components/service-center/components/ServiceTicket';
import PrivateRoutes from './components/private-route/PrivateRoutes';
import HabitTracker from './components/growth-enabler/objective-tracker/HabitTracker';
import RoleAccess from './components/private-route/RoleAccess';
import AdminPage from './components/admin-corner/AdminPage';
import NotFoundPage from './components/private-route/NotFoundPage';
import ServiceBoard from './components/service-center/ServiceBoard';
import Profile from './components/growth-enabler/profile/Profile';

function App() {
  const login = window.localStorage.getItem('isLoggedIn');
  const userRole = window.localStorage.getItem('userRole');

  return (

      <Router>
        <Routes>
          <Route path="/" element={login ? <PinBoard /> : <Login />}/>
          <Route path="/signup" element={<Signup />}/>
          <Route element={<PrivateRoutes isAuth={login} />}>
            <Route path="/not-found" element={<NotFoundPage />}/>
            <Route path="/pin-board" element={<PinBoard />}/>
            <Route path="/growth-enabler/profile" element={<Profile />}/>
            <Route path="/service-center/tickets" element={<ServiceTicket />}/>
            <Route path="/service-center/lunch-order" element={<LunchOrder />}/>
            <Route path='/expense-tracker/team' element={<ExpenseTrackerApp isAdmin={userRole} />}/>
            <Route path='/expense-tracker/personal' element={<PersonalExpenseTrackerApp isAdmin={userRole} />}/>
            <Route path="/growth-enabler/habit" element={<HabitTracker />}/>
            <Route element={<RoleAccess isAdmin={userRole} />}>
              <Route path="/service-center/lunch-admin" element={<LunchAdmin isAdmin={userRole} />}/>
              <Route path="/service-center/service-board" element={<ServiceBoard />}/>
              <Route path='/news-pocket' element={<NewsPocket />}/>
              <Route path='/mixed-bag' element={<AdminPage />}/>
            </Route>
          </Route>
        </Routes>
      </Router>

  );
}

export default App;
