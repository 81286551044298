import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PokePet from './PokePet';

import probationEgg from '../../../assets/eggs/Egg_2_km.png';
// GEN2 POKEMON 3-STEPS | HATCH: PASS 1Y | EVOLUTION: ATTENDANCE
import anniversary1Egg from '../../../assets/eggs/Egg_5_km.png';
// GEN3 POKEMON 3-STEPS | HATCH: PASS 2Y | EVOLUTION: ATTENDANCE
import anniversary2Egg from '../../../assets/eggs/Egg_10_km.png';
// GEN4 POKEMON 3-STEPS | HATCH: PASS 3Y | EVOLUTION: ATTENDANCE
import anniversary3Egg from '../../../assets/eggs/Egg_12_km.png';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1
    }
  };

const PokePetCarousel = ({loggedInUser}) => {
    const anniversary1EggText = "This Pokemon egg will hatch on your 1st work anniversary!";
    const anniversary1PetText = "You got me by being persistent and effective during your 1st year here!";

    const anniversary2EggText = "This Pokemon egg will hatch on your 2nd work anniversary!";
    const anniversary2PetText = "Thank you for 2 amazing years of effectiveness and diligence!";

    const anniversary3EggText = "This Pokemon egg will hatch on your 3rd work anniversary!";
    const anniversary3PetText = "Thank you for 3 amazing years of effectiveness and diligence!";

    const probationEggText = "Your time and effort in probationary period will help this Pokemon egg hatch!";
    const probationPetText = "Your awesome performance in probationary time brought me to life!";

    return (
        <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}
            rewind={true}
            arrows={false}
            showDots={true}
        >
            <PokePet petId={loggedInUser?.pokepet?.anniversaryPet?.probationPetId} eggText={probationEggText} petText={probationPetText} eggImg={probationEgg}/>
            <PokePet petId={loggedInUser?.pokepet?.anniversaryPet?.anniversary1PetId} eggText={anniversary1EggText} petText={anniversary1PetText} eggImg={anniversary1Egg}/>
            <PokePet petId={loggedInUser?.pokepet?.anniversaryPet?.anniversary2PetId} eggText={anniversary2EggText} petText={anniversary2PetText} eggImg={anniversary2Egg}/>
            <PokePet petId={loggedInUser?.pokepet?.anniversaryPet?.anniversary3PetId} eggText={anniversary3EggText} petText={anniversary3PetText} eggImg={anniversary3Egg}/>
        </Carousel>
  )
}

export default PokePetCarousel
