export default (state, action) => {
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    switch(action.type) {
        case 'GET_LUNCH_MENUS':
            return {
                ...state,
                loading: false,
                lunchMenus: [action.payload.filter(menu => (menu.userOrg === userObj.org)).reverse()[0]]
            }
        case 'ADD_LUNCH_MENUS':
            return {
                ...state,
                lunchMenus: [action.payload, ...state.lunchMenus]
            }
        case 'GET_LUNCH_ORDERS':
            return {
                ...state,
                loading: false,
                lunchOrders: action.payload.filter(order => (order.customerOrg === userObj.org && order.customerId === userObj._id)).reverse()
            }
        case 'ORDER_LUNCH':
            return {
                ...state,
                lunchOrders: [action.payload, ...state.lunchOrders]
            }
        case 'LUNCH_MENUS_ERROR':
            return {
                ...state,
                error: action.payload
            }
        case 'ORDER_LUNCH_ERROR':
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}