import React, { useState, useContext } from 'react';
import { TaskContext } from '../TaskState';


const AddTicket = () => {

    const [ summary, setSummary] = useState('');
    const [ detail, setDetail] = useState('');
    const [ importance, setImportance] = useState('Medium');

    const { addToDo } = useContext(TaskContext);

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);

    const onSubmit = e => {
        e.preventDefault();

        const newTicket = {
            id: Math.floor(Math.random() * 100000000),
            desc: summary,
            detail: detail,
            requester: `${userObj.firstName} ${userObj.lastName}`,
            importance: importance,
            doerOrg: userObj.org,
            dueDate: Date(),
            completed: false,
            doing: false
        }

        addToDo(newTicket);
        alert("Your request has been submitted.");

        setSummary('');
        setDetail('');
        setImportance('Medium');

        window.location.reload(false);
    }
    
    return (
        <div  style={{ margin: '0 2vw'}}>
        <h3>Create a Service Ticket</h3>
        <form onSubmit={onSubmit} style={{ display: 'flex', justifyContent: 'center'}}>
            <div className='form-control' style={{ width: '100%'}}>
                <label htmlFor='text'><b>What support do you need? <i style={{fontSize: '12px'}}>(* required)</i></b></label>
                <input type='text' value={summary} onChange={(e) => setSummary(e.target.value)} 
                    placeholder='Enter more details...' />
                <label htmlFor='text'><b>How important do you think it is?</b><i style={{fontSize: '12px'}}> *</i><br/></label>
                <select className="minimal" id="importance" style = {{height:'40px'}} value={importance} onChange={(e) => setImportance(e.target.value)}>
                    <option key="high">High</option>
                    <option key="medium">Medium</option>
                    <option key="low">Low</option>
                </select>
                <label htmlFor='text'><b>More details about your needs <i style={{fontSize: '12px'}}>(optional)</i></b></label>
                <textarea style={{width: '100%'}} value={detail} onChange={(e) => setDetail(e.target.value)}
                    placeholder='Enter a message...'></textarea>
                <button className='btn' type='submit'>Submit</button>
            </div>
        </form>
        </div>
    )
}

export default AddTicket
