import { React, useState } from "react";
import TextField from "@mui/material/TextField";
import List from "./SearchList"
import "../../App.css";

function SearchBar() {
    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputText(lowerCase);
    };

  return (
    <div className='subcontainer-search'>
      <div style={{display: 'flex'}}>
        <h4 style={{padding: '5px 10px 0', fontSize: '24px'}}>&#x1f50d;</h4>
        <TextField
            id="outlined-basic"
            onChange={inputHandler}
            variant='filled'
            fullWidth
            placeholder="Search transactions with name, date or description..."
            color='success'
        />
      </div>
      <List input={inputText} />
    </div>
  );
}

export default SearchBar;