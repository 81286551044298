export default (state, action) => {
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    switch(action.type) {
        case 'GET_TODOS':
            return {
                ...state,
                loading: false,
                toDos: action.payload.filter(toDo => (toDo.doerOrg === userObj.org)).reverse()
            }
        case 'ADD_TODO':
            return {
                ...state,
                toDos: [action.payload, ...state.toDos]
            }
        case 'EDIT_TODO':
            return {
                ...state,
                toDos: [action.payload, ...state.toDos]
            }
        case 'TODOS_ERROR':
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}