import React, { useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';

const QuoteList = () => {
    const { quotes } = useContext(GlobalContext);

    let i = Math.floor(Math.random() * 35);
    
  return (
    <>
      {quotes[i] ? `${quotes[i].author} \xa0\xa0\:\xa0\xa0\ ❝ ${quotes[i].quote} ❞` : ''}
    </>
  )
}

export default QuoteList
