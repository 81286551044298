import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logout from './user-management/Logout';
import QuoteList from './QuoteList';
import { GlobalContext } from '../context/GlobalState'

const Header = () => {
  
  const { getQuotes } = useContext(GlobalContext);

  useEffect(() => {
      getQuotes();
      // eslint-disable-next-line
  }, []);

  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);

  const userFullname = `${userObj.firstName} ${userObj.lastName}`;
  const userRole = userObj.type;

  return (
      <div className="navbar">
        <div>
          <div className='accordion' style={{display: userRole !== 'admin' ? 'none' : 'inherit' }}>
            <details>
              <summary>&#128157; | Administration Hub</summary>
              <Link className='dropdown-navlink' to="/service-center/service-board">Delivery Board</Link>
              <Link className='dropdown-navlink' to="/service-center/lunch-admin">Lunch Admin</Link>
              <Link className='dropdown-navlink' to="/mixed-bag">Mixed Bag</Link>
              <Link className='dropdown-navlink' to="/news-pocket">News Pocket</Link>
            </details>
          </div>
          <div className='accordion'>
            <details>
              <summary>&#129309; | Service Assistant</summary>
                <Link className='dropdown-navlink' to="/service-center/tickets">Need A Support</Link>
                <Link className='dropdown-navlink' to="/service-center/lunch-order">Lunch Order</Link>
            </details>
          </div>
          <div className='accordion'>
            <details>
              <summary>&#128176; | Financial Assistant</summary>
              <Link className='dropdown-navlink' to="/expense-tracker/team">Team Ledger</Link>
              <Link className='dropdown-navlink' to="/expense-tracker/personal">Personal Ledger</Link>
            </details>
          </div>
          <div className='accordion'>
            <details>
              <summary>&#129716; | Growth Enabler</summary>
                <Link className='dropdown-navlink' to="/growth-enabler/profile">Profile</Link>
                <Link className='dropdown-navlink' to="/growth-enabler/habit">Habits</Link>
            </details>
          </div>
        </div>
        <div style={{display: 'inline-flex', margin: 0}}>
          <div id='scroll-container'>
            <h4 id='scroll-text'>
              <i>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Good day to <em style={{color: '#FFC107'}}>{userFullname}</em>!
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              ...
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Let's uplift your day with a wise word from &nbsp;
                <QuoteList />
              </i>
            </h4>
          </div>
          <div style={{ width: 'fit-content'}}>
            <Link className='pin-btn' to="/pin-board" style={{ padding: '0.5rem 0.2rem 0' }}>&#128075; </Link>
          </div>
          <Logout />
        </div>
      </div>
  )
}

export default Header
