import React from 'react';
import { numberWithCommas } from '../../utils/NumberFormat';

const CatSummaryItem = ({item}) => {

    return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <li>
                    <span style={{width: '100%', textAlign: "center", fontSize: "14px"}}>
                        <b>{item.category}</b></span>
                    <span style={{width: '100%', textAlign: "center", fontSize: "14px"}} className='money minus'>
                        {numberWithCommas(item.expense)}</span>
                </li>
            </div>
  )
}

export default CatSummaryItem
