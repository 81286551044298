import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NotesIcon from '@mui/icons-material/Notes';

export default function Comment({task}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}
        sx = {{
            color: '#416129',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            height: 20,
            border: '1px solid #416129',
            borderRadius: 10,
            marginTop: 0.5,
            marginLeft: 0.5,
            minWidth: 'fit-content',
            ':hover': {
              backgroundColor: '#416129',
              color: '#fff',
            }
        }}>
        <NotesIcon fontSize='6' />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2, fontSize: 12, }}>
            Comming Soon!
        </Typography>
      </Popover>
    </div>
  );
}