import React from 'react';
import Header from './Header';
import { GlobalProvider } from '../context/GlobalState';
import RollingText from './RollingText';

function PinBoard() {

  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);;
  const arr = userObj.firstName.toUpperCase().split('');

  const today = new Date();
  const currentDay = today.toLocaleDateString('en-US', { weekday: 'long' });

  return (
    <GlobalProvider>
      <Header />
      <br/>
      <h1 style={{textAlign: 'center', color: '#416129'}}>
        <br/>
        Happy {currentDay}! ✨
        <p style={{ color: '#FFC107', marginBottom: 0 }}>{`${userObj.firstName} ${userObj.lastName}`}</p>
        <p style={{ fontSize: '1rem' }}><i>({userObj.email})</i></p>
        <br/>
        Your Amazing Traits Will Help You Shine Today 🌟
      </h1>
      <h1 className='h1-text'>
        {arr.map(char => (<RollingText key = {Math.floor(Math.random() * 100000000)} firstNameChar = {char}/>))}
      </h1>
    </GlobalProvider>
  )
}

export default PinBoard
