import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import loginimg from "../../assets/loginimg.jpeg";

function Login() {
  
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')

    async function submit(e){
      e.preventDefault();

      try{

          await axios.post("/api/v1/users/login",{
              email,password
          })
          .then(res => {
                if (res.data.data.type === 'none') {
                    alert('Your registration is being reviewed. Please get back later.')
                } else if (res.data.log === "exist"){
                    window.localStorage.setItem('isLoggedIn', true);
                    window.localStorage.setItem('userRole', res.data.data.type);
                    window.localStorage.setItem('user', JSON.stringify(res.data.data));
                    window.location.reload(false);
                } else if (res.data.log === "wrong-pwd") {
                    alert('Incorrect password')
                }
          })
          .catch(e=>{
              alert("Please check your network connection, your inputs or... simply you has not signed up! 😊")
              console.log(e);
          })

      }
      catch(e){
          console.log(e);

      }

  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img src={loginimg} alt="main-img" className="img-container"/>
        
        <div className="login-container">

            <h1>Login</h1>

            <form action="POST">
                <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="&#x1F4E7; | Email"  />
                <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="&#128272; | Password"  />
                <button className="btn" type="submit" onClick={submit}>Log me in</button>

            </form>

            <br />

            <Link to="/signup">I'm New, Sign Me Up</Link>

        </div>
    </div>
)
}

export default Login
