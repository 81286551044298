import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PokePet = ({petId, eggText, petText, eggImg}) => {

    const [petName, setPetName] = useState('');

    useEffect(() => {
        petId === undefined || petId === 0
        ? setPetName('unknown')
        : axios.get(`https://pokeapi.co/api/v2/pokemon/${petId}`)
        .then(res => {
            setPetName(res.data.name)
        });
    }, [petId !== undefined]);

    const pokemonImg = petId === undefined || petId === 0
    ? eggImg
    : `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/animated/${petId}.gif`;

    return (
        <div className='pet-container'>
            {petId === undefined || petId === 0
                ? <small style={{ textAlign: 'center', padding: '0.5rem' }}>{eggText} &#127808;</small>
                : <small style={{ textAlign: 'center', padding: '0.5rem' }}>{petText} &#128151;</small>
            }
            <div className="img-wrap">
                <img src={pokemonImg} alt='an awesome Pokemon...' style={petId === undefined || petId === 0 ? {width: '80px', paddingBottom: '1rem', margin: '0.5rem'} : {height: '80px', margin: '1rem'}}/>
                <b className="img-description">{petName.toUpperCase()}</b>
            </div>
        </div>
    )
}

export default PokePet