import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { numberWithCommas } from '../../utils/NumberFormat';

const PersonIncomeExpenses = () => {

    const { transactions } = useContext(GlobalContext);
    const dataShared = transactions.filter(item => item.privacy !== true);

    const { users } = useContext(GlobalContext);

    const payerOptions = [];

    for (let i = 0; i < users.length; i++) {
        payerOptions.unshift(`${users[i].firstName} ${users[i].lastName}`)
    }

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const userFullname = `${userObj.firstName} ${userObj.lastName}`;

    const [person, setPerson] = useState(userFullname);

    const onChange = e => {
        setPerson(e.target.value);
    };

    const amounts = dataShared.filter(item => item.payer === person).map(transaction => transaction.amount);
    const income = amounts.filter(item => item > 0).reduce((acc, item) => (acc += item), 0);
    const expense = amounts.filter(item => item < 0).reduce((acc, item) => (acc -= item), 0);
    const balance = income - expense;

    return (
        <div className='person-inc-exp-container'>
                <select style={{border: 'none', margin: '0 0.5rem 0 0', padding: '0 0.5rem 0 0'}} id="names" value={person} onChange={onChange}>
                        {payerOptions.map((option, id) => <option key={id}>{option}</option>)}
                </select>
                <p className={balance >= 0 ? 'money plus' : 'money minus'} style={{padding: '0.5rem 0'}}>{numberWithCommas(balance)}</p>
        </div>
    )
}

export default PersonIncomeExpenses
