import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { numberWithCommas } from '../../utils/NumberFormat';

const Balance = () => {

    const { transactions } = useContext(GlobalContext);
    const dataShared = transactions.filter(item => item.privacy !== true);
    const amounts = dataShared.map(transaction => transaction.amount);
    const total = amounts.reduce((acc, item) => (acc += item), 0);

  return (
    <div className='balance-container'>
      <h4>Balance</h4>
      <h1 className={total >= 0 ? 'balance plus' : 'balance minus'}>{numberWithCommas(total)}</h1>
      <h4>VND</h4>
    </div>
  )
}

export default Balance
