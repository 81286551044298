import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import MonthlySummaryItem from '../expense-tracker/MonthlySummaryItem';

const PersonalMonthlySummary = () => {
    const { transactions } = useContext(GlobalContext);
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const dataByUser = transactions.filter(item => item.userId === userObj._id);

    function monthOf(str) {
        const date = new Date(str);
        return date.getMonth() + 1;
    };

    const accumToMonth = [];

    for (let index = 0; index < 12; index++) {
        const accumByMonth = {};

        for (let i = 0; i < dataByUser.length; i++) {
            if (monthOf(dataByUser[i].createdAt) === index + 1) {
                let month = index + 1;
                let transactionsToMonth = dataByUser.filter(transaction => monthOf(transaction.createdAt) <= month);
                let monthIncome = transactionsToMonth.map(transaction => transaction.amount).filter(item => item > 0).reduce((acc, item) => (acc += item), 0);
                let monthExpense = transactionsToMonth.map(transaction => transaction.amount).filter(item => item < 0).reduce((acc, item) => (acc -= item), 0);
                accumByMonth.month = month;
                accumByMonth.monthIncome = monthIncome;
                accumByMonth.monthExpense = monthExpense;
            }
        }

        if (accumByMonth.month === index + 1) {
            accumToMonth.unshift(accumByMonth);
        }
    }

    const byMonth = [];

    for (let index = 0; index < 12; index++) {
        const accumByMonth = {};

        for (let i = 0; i < dataByUser.length; i++) {
            if (monthOf(dataByUser[i].createdAt) === index + 1) {
                let month = index + 1;
                let transactionsInMonth = dataByUser.filter(transaction => monthOf(transaction.createdAt) === month);
                let monthIncome = transactionsInMonth.map(transaction => transaction.amount).filter(item => item > 0).reduce((acc, item) => (acc += item), 0);
                let monthExpense = transactionsInMonth.map(transaction => transaction.amount).filter(item => item < 0).reduce((acc, item) => (acc -= item), 0);
                accumByMonth.month = month;
                accumByMonth.monthIncome = monthIncome;
                accumByMonth.monthExpense = monthExpense;
            }
        }

        if (accumByMonth.month === index + 1) {
            byMonth.unshift(accumByMonth);
        }
    }

    const [boxChecked, setRadio] = useState();

    return (
        <div>
            <h3>Summary
                <div className='filter-buttons'>
                        <input type='radio' id='filter-button' name='swap' value='accumulated' onClick={(e)=>{setRadio(e.target.value)}}></input>
                        <label htmlFor='filter-button'>Accumulated</label>
                        <input type='radio' id='filter-button' name='swap' value='month' onClick={(e)=>{setRadio(e.target.value)}}></input>
                        <label htmlFor='filter-button'>Month</label>
                </div>
            </h3>
            <div className='list-heading' style={{padding: '0 10px 10px 10px'}}>
                <span style={{width: '6rem', textAlign: "center", fontSize: "1rem"}}>Month</span>
                <span style={{width: '6rem', textAlign: "right", fontSize: "1rem"}}>Funding</span>
                <span style={{width: '6rem', textAlign: "right", fontSize: "1rem"}}>Spending</span>
                <span style={{width: '6rem', textAlign: "right", fontSize: "1rem"}}>Balance</span>
            </div>
            <ul className='month-list'>
                { boxChecked === 'accumulated' ? 
                    accumToMonth.map((item, id) => (<MonthlySummaryItem key={id} item={item}/>)) : 
                    byMonth.map((item, id) => (<MonthlySummaryItem key={id} item={item}/>))}
            </ul>
        </div>
    )
}

export default PersonalMonthlySummary
