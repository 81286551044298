import React, { useState } from 'react';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import EventIcon from '@mui/icons-material/Event';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Filter2OutlinedIcon from '@mui/icons-material/Filter2Outlined';
import Looks3Icon from '@mui/icons-material/Looks3';
import Filter3OutlinedIcon from '@mui/icons-material/Filter3Outlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ExtensionIcon from '@mui/icons-material/Extension';

const ProfileMainEditByAdmin = ({user, state, setState}) => {

    const [ team, setTeam ] = useState(user?.team);
    const [ workInfo, setWorkInfo ] = useState(user?.workInfo);
    const [ internship, setInternship ] = useState(user?.contractMilestones?.internship);
    const [ collaboration, setCollaboration ] = useState(user?.contractMilestones?.collaboration);
    const [ probation, setProbation ] = useState(user?.contractMilestones?.probation);
    const [ empContract1, setEmpContract1 ] = useState(user?.contractMilestones?.empContract1);
    const [ empContract2, setEmpContract2 ] = useState(user?.contractMilestones?.empContract2);
    const [ empContract3, setEmpContract3 ] = useState(user?.contractMilestones?.empContract3);

    const formatDate = (str) => {
        return str.slice(0, 10)
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <h3 style={{marginTop: '1rem', width: '100%'}}>Work Information</h3>
            <div className='profile-part'>
                <AssignmentIndIcon className='icon-container'/>
                <span className='profile-span-25'>
                    Designation
                    <input type='text' id='designation' value={workInfo?.designation} 
                        onChange={(e) => {
                            setWorkInfo({...workInfo, designation: e.target.value});
                            setState({...state, workInfo: {...state.workInfo, designation: e.target.value}})
                        }} 
                        placeholder={workInfo?.designation} />
                </span>
                <ExtensionIcon className='icon-container'/>
                <span className='profile-span-25'>
                    Role in Team
                    <input type='text' id='designation' value={workInfo?.role} 
                        onChange={(e) => {
                            setWorkInfo({...workInfo, role: e.target.value});
                            setState({...state, workInfo: {...state.workInfo, role: e.target.value}})
                        }} 
                        placeholder={workInfo?.role} />
                </span>
                <GroupIcon className='icon-container'/>
                <span className='profile-span-25'>
                    Team
                    <input type='text' id='team' value={team} 
                        onChange={(e) => {
                            setTeam(e.target.value);
                            setState({...state, team: e.target.value})
                        }} 
                        placeholder={team} />
                </span>
                <GroupsIcon className='icon-container'/>
                <span className='profile-span-25'>
                    Department
                    <input type='text' id='department'  value={workInfo?.department} 
                        onChange={(e) => {
                            setWorkInfo({...workInfo, department: e.target.value});
                            setState({...state, workInfo: {...state.workInfo, department: e.target.value}})
                        }} 
                        placeholder={workInfo?.department} />
                </span>
            </div>
            <br/>
            <h3 style={{marginTop: '1rem', width: '100%'}}>Contract Milestones</h3>
            <div className='profile-part'>
                <CalendarTodayIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Internship Start
                    <input type='date' id='internship-date' defaultValue={formatDate(internship?.startDate)}
                        onChange={(e) => {
                            setInternship({...internship, startDate: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, internship: {
                                        ...state.contractMilestones.internship, startDate: e.target.value
                                    }}})
                        }} />
                </span>
                <LocalLibraryIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Internship Contract
                    <input type='text' id='internship-contract' value={internship?.internshipContract} 
                        onChange={(e) => {
                            setInternship({...internship, internshipContract: e.target.value });
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, internship: {
                                        ...state.contractMilestones.internship, internshipContract: e.target.value
                                    }}})
                        }} 
                        placeholder={internship?.internshipContract} />
                </span>
                <LocalLibraryOutlinedIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Appendix
                    <input type='text' id='internship-annex' value={internship?.internshipAnnex1} 
                        onChange={(e) => {
                            setInternship({...internship, internshipAnnex1: e.target.value });
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, internship: {
                                        ...state.contractMilestones.internship, internshipAnnex1: e.target.value
                                    }}})
                        }} 
                        placeholder={internship?.internshipAnnex1} />
                </span>
            </div>
            <div className='profile-part'>
                <DateRangeIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Collaboration Start
                    <input type='date' id='internship-date' defaultValue={formatDate(collaboration?.collaborationDate)}
                        onChange={(e) => {
                            setCollaboration({...collaboration, collaborationDate: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, collaboration: {
                                        ...state.contractMilestones.collaboration, collaborationDate: e.target.value
                                    }}})
                        }} />
                </span>
                <HandshakeIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Collaboration Contract
                    <input type='text' id='internship-contract' value={collaboration?.collaborationContract} 
                        onChange={(e) => {
                            setCollaboration({...collaboration, collaborationContract: e.target.value });
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, collaboration: {
                                        ...state.contractMilestones.collaboration, collaborationContract: e.target.value
                                    }}})
                        }} 
                        placeholder={collaboration?.collaborationContract} />
                </span>
                <HandshakeOutlinedIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Appendix
                    <input type='text' id='internship-annex' value={collaboration?.collaborationAnnex1} 
                        onChange={(e) => {
                            setCollaboration({...collaboration, collaborationAnnex1: e.target.value });
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, collaboration: {
                                        ...state.contractMilestones.collaboration, collaborationAnnex1: e.target.value
                                    }}})
                        }} 
                        placeholder={collaboration?.collaborationAnnex1} />
                </span>
            </div>
            <div className='profile-part'>
                <EventIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Probation Start
                    <input type='date' id='probation-date' defaultValue={formatDate(probation?.probationDate)}
                        onChange={(e) => {
                            setProbation({...probation, probationDate: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, probation: {
                                        ...state.contractMilestones.probation, probationDate: e.target.value
                                    }},
                                workInfo: {...state.workInfo, anniversary: e.target.value}})
                            }} />
                </span>
                <ConfirmationNumberIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Probation Contract
                    <input type='text' id='probation-contract' value={probation?.probationContract} 
                        onChange={(e) => {
                            setProbation({...probation, probationContract: e.target.value });
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, probation: {
                                        ...state.contractMilestones.probation, probationContract: e.target.value
                                    }}})
                        }} 
                        placeholder={probation?.probationContract} />
                </span>
                <ConfirmationNumberOutlinedIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Appendix
                    <input type='text' id='probation-annex1' value={probation?.probationAnnex1} 
                        onChange={(e) => {
                            setProbation({...probation, probationAnnex1: e.target.value });
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, probation: {
                                        ...state.contractMilestones.probation, probationAnnex1: e.target.value
                                    }}})
                        }} 
                        placeholder={probation?.probationAnnex1} /> 
                </span>
            </div>
            <div className='profile-part'>
                <EventNoteIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Official Employment Date
                    <input type='date' id='employment-date' defaultValue={formatDate(empContract1?.contractDate1)}
                        onChange={(e) => {
                            setEmpContract1({...empContract1, contractDate1: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract1: {
                                        ...state.contractMilestones.empContract1, contractDate1: e.target.value
                                    }}})
                            }} />
                </span>
                <LooksOneIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Employment Contract
                    <input type='text' id='employment-contract1' value={empContract1?.contract1No}
                        onChange={(e) => {
                            setEmpContract1({...empContract1, contract1No: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract1: {
                                        ...state.contractMilestones.empContract1, contract1No: e.target.value
                                    }}})
                            }}
                        placeholder={empContract1?.contract1No} />
                </span>
                <Filter1OutlinedIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Appendix
                    <input type='text' id='employment-annex11' value={empContract1?.annex11}
                        onChange={(e) => {
                            setEmpContract1({...empContract1, annex11: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract1: {
                                        ...state.contractMilestones.empContract1, annex11: e.target.value
                                    }}})
                            }}
                        placeholder={empContract1?.annex11} />
                </span>
            </div>
            <div className='profile-part'>
                <EventRepeatIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Employment Renewal Date
                    <input type='date' id='renewal-date1' defaultValue={formatDate(empContract2?.contractDate2)}
                        onChange={(e) => {
                            setEmpContract2({...empContract2, contractDate2: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract2: {
                                        ...state.contractMilestones.empContract2, contractDate2: e.target.value
                                    }}})
                            }} />
                </span>
                <LooksTwoIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Employment Contract
                    <input type='text' id='employment-contract2' value={empContract2?.contract2No}
                        onChange={(e) => {
                            setEmpContract2({...empContract2, contract2No: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract2: {
                                        ...state.contractMilestones.empContract2, contract2No: e.target.value
                                    }}})
                            }}
                        placeholder={empContract2?.contract2No} />
                </span>
                <Filter2OutlinedIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Appendix
                    <input type='text' id='employment-annex21' value={empContract2?.annex21}
                        onChange={(e) => {
                            setEmpContract2({...empContract2, annex21: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract2: {
                                        ...state.contractMilestones.empContract2, annex21: e.target.value
                                    }}})
                            }}
                        placeholder={empContract2?.annex21} />
                </span>
            </div>
            <div className='profile-part'>
                <EventRepeatIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Employment Renewal Date
                    <input type='date' id='renewal-date2' defaultValue={formatDate(empContract3?.contractDate3)}
                        onChange={(e) => {
                            setEmpContract3({...empContract3, contractDate3: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract3: {
                                        ...state.contractMilestones.empContract3, contractDate3: e.target.value
                                    }}})
                            }} />
                </span>
                <Looks3Icon className='icon-container'/>
                <span className='profile-span-30'>
                    Employment Contract
                    <input type='text' id='employment-contract3' value={empContract3?.contract3No}
                        onChange={(e) => {
                            setEmpContract3({...empContract3, contract3No: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract3: {
                                        ...state.contractMilestones.empContract3, contract3No: e.target.value
                                    }}})
                            }}
                        placeholder={empContract3?.contract3No} />
                </span>
                <Filter3OutlinedIcon className='icon-container'/>
                <span className='profile-span-30'>
                    Appendix
                    <input type='text' id='employment-annex31' value={empContract3?.annex31}
                        onChange={(e) => {
                            setEmpContract3({...empContract3, annex31: e.target.value});
                            setState({
                                ...state, contractMilestones: {
                                    ...state.contractMilestones, empContract3: {
                                        ...state.contractMilestones.empContract3, annex31: e.target.value
                                    }}})
                            }}
                        placeholder={empContract3?.annex31} />
                </span>
            </div>
        </div>
    )
}

export default ProfileMainEditByAdmin