import React from 'react'

const MixedBag = () => {
  return (
    <div>
      This is mixed bag
    </div>
  )
}

export default MixedBag
