import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import loginimg from "../../assets/loginimg.jpeg";

function Signup() {
  const navigate = useNavigate();

  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [org, setorg] = useState('');
  const [team, setTeam] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function submit(e){
      e.preventDefault();

      try{

          await axios.post("/api/v1/users/signup", {
            firstName, lastName, email, password, org, team
          })
          .then(() => {
            navigate("/",{state:{id:email}})
            alert("Thank you for registering! We will take time to review your submission and get back to you via email shortly!");
          })
          .catch(e=>{
            if(e.message === "Request failed with status code 409"){
              alert("User with this email already exists. Please go to Log in page or sign up with different email. Thanks!")
            } else {
              alert("Please check your inputs.")
              console.log(e.message);
            }
          })

      }
      catch(e){
          console.log(e);

      }

  }


  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <img src={loginimg} alt="main-img" className="img-container"/>
      <div className="login-container">

          <h1>Signup</h1>

          <form action="POST">
              <input type="text" onChange={(e) => { setfirstName(e.target.value) }} placeholder="First Name"  />
              <input type="text" onChange={(e) => { setlastName(e.target.value) }} placeholder="Last Name"  />
              <input type="text" onChange={(e) => { setorg(e.target.value) }} placeholder="Organization"  />
              <input type="text" onChange={(e) => { setTeam(e.target.value) }} placeholder="Team"  />
              <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Email"  />
              <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" />
              <button className="btn" type="submit" onClick={submit}>Register</button>

          </form>

          <br />

          <Link to="/">Back to Login</Link>

      </div>
    </div>
  )
}

export default Signup
