import React, { useState, useEffect } from 'react';
import TextTransition, { presets } from 'react-text-transition';

const arr1 = [
    'adaptable', 'adept', 'affectionate', 'agreeable', 'alluring', 'amazing', 'ambitious', 'amiable', 'ample', 'approachable', 'awesome', 
    'blithesome', 'bountiful', 'brave', 'breathtaking', 'bright', 'brilliant', 
    'capable', 'captivating', 'charming', 'competitive', 'confident', 'considerate', 'courageous', 'creative', 'customer-focused', 
    'dazzling', 'determined', 'devoted', 'diligent', 'diplomatic', 'dynamic', 
    'educated', 'efficient', 'elegant', 'enchanting', 'energetic', 'engaging', 'engrossing', 'excellent', 
    'fabulous', 'faithful', 'fantastic', 'fast-paced', 'favorable', 'fearless', 'flexible', 'focused', 'fortuitous', 'frank', 'friendly', 'funny', 
    'generous', 'giving', 'gleaming', 'glimmering', 'glistening', 'glittering', 'glowing', 'gorgeous', 'gregarious', 'gripping', 'gleeful',
    'hardworking', 'heartwarming', 'helpful', 'hilarious', 'honest', 'humorous', 
    'imaginative', 'incredible', 'independent', 'inquisitive', 'insightful', 
    'kind', 'knowledgeable', 
    'likable', 'lovely', 'loving', 'loyal', 'lustrous', 
    'magnificent', 'marvelous', 'mirthful', 'moving', 
    'nice', 'noteworthy', 'noble', 'nifty', 'neighborly', 'neat', 'nurturing',
    'open-minded', 'optimistic', 'organized', 'outstanding', 
    'passionate', 'patient', 'perfect', 'persistent', 'personable', 'philosophical', 'plucky', 'polite', 'powerful', 'productive', 'proficient', 'propitious', 
    'qualified', 
    'ravishing', 'relaxed', 'remarkable', 'resourceful', 'responsible', 'romantic', 'rousing', 
    'self-confident', 'sensible', 'sincere', 'sleek', 'sparkling', 'spectacular', 'spellbinding', 'splendid', 'stellar', 'stunning', 'stupendous', 'super', 
    'technological', 'thought-provoking', 'thoughtful', 'twinkling', 
    'unique', 'upbeat', 
    'vibrant', 'vivacious', 'vivid', 
    'warm-hearted', 'willing', 'wondrous', 
    'yearning', 'youthful',
    'zestful'
];

const RollingText = ({firstNameChar}) => {

    const [index, setIndex] = useState(0);
 
    useEffect(() => {
        const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        5000,
        );
        return () => clearTimeout(intervalId);
    }, []);

    const filterArr = (arr) => {
        const filteredArr = arr.filter(text => text.startsWith(firstNameChar.toLowerCase()))
        filteredArr.forEach( (str, i, arr) => {
            arr[i] = str.substring(1);
        });
        filteredArr.sort(() => Math.random() * 10);
        return filteredArr;
    }
    
  return (
    <span className='text-container'>
        <b className='text-container-firstchar'>{firstNameChar}</b>
        <b className='text-container-b'>
            {<TextTransition springConfig={presets.gentle}>{filterArr(arr1)[index % filterArr(arr1).length]}</TextTransition>}
        </b>
    </span>
  )
}

export default RollingText
