import React, { useContext, useEffect } from 'react';
import { ServiceContext } from '../service-center/ServiceState';
import LunchOrderedItem from './LunchOrderedItem';

const LunchOrdered = () => {

    const { getLunchOrder, lunchOrders } = useContext(ServiceContext);

    useEffect(() => {
        getLunchOrder();
        // eslint-disable-next-line
    }, []);

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const userFullname = `${userObj.firstName} ${userObj.lastName}`

    const currentDate = new Date().toJSON().slice(0, 10);
    const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });

    return (
        <div>
            <h2 style={{width: '100%'}}>Lunch Catering</h2>
            <h3 style={{width: '100%'}}>
                {userFullname}'s Lunch Orders
                <span style={{textAlign: 'center'}}>{currentDay}, {currentDate}</span>
            </h3>
            <div style={{height: '25vh', overflowY: 'scroll'}}>
                {lunchOrders.map(lunch => <LunchOrderedItem key={lunch._id} lunch={lunch}/>)}
            </div>
        </div>
    )
}

export default LunchOrdered