import React, {useContext, useState, useEffect} from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { io } from 'socket.io-client';

const socket = io('http://localhost:3001');

const AddTransation = () => {
    
    const [text, setText] = useState('');
    const [category, setCategory] = useState('');
    const [doc, setDoc] = useState('');

    const { users, getUsers } = useContext(GlobalContext);

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, []);

    const payerOptions = [];

    for (let i = 0; i < users.length; i++) {
        payerOptions.unshift(`${users[i].firstName} ${users[i].lastName}`)
    }

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);    
    const userFullname = `${userObj.firstName} ${userObj.lastName}`;
    
    const [payer, setPayer] = useState(userFullname);
    const [amount, setAmount] = useState('');
    const [privacy, setPrivacy] = useState(false);

    const { addTransaction } = useContext(GlobalContext);

    const onSubmit = e => {
        e.preventDefault();

        const payerId = users.filter(item => `${item.firstName} ${item.lastName}` === payer)[0]._id;

        const newTransaction = {
            id: Math.floor(Math.random() * 100000000),
            text,
            category,
            payer,
            userId: payerId,
            userOrg: userObj.org,
            amount: +amount,
            doc,
            privacy
        }

        if (newTransaction.text === '') {
            return alert("Description must be filled out")
        } else if (newTransaction.payer === '') {
            return alert("Payer must be filled out")
        }

        addTransaction(newTransaction);

        setText('');
        setPayer(userFullname);
        setAmount('');
        setCategory('');
        setDoc('');
        setPrivacy(false);

        socket.emit('add-transaction', '')
    }

    return (
        <div>
        <h3>Add new transaction</h3>
        <form onSubmit={onSubmit}>
            <div className='form-control' style={{width: '100%'}}>
                <label htmlFor='text'><b>Short Description <i style={{fontSize: '12px'}}>(* required)</i></b></label>
                <input type='text' value={text} onChange={(e) => setText(e.target.value)} 
                placeholder='Enter description...' />
            </div>
            <div className='form-control-small'>
                <div className='form-control'>
                    <label htmlFor='text'><b>Category</b></label>
                    <input type='text' value={category} onChange={(e) => setCategory(e.target.value)}
                    placeholder='Enter category name...' />
                </div>
                <div className='form-control'>
                    <label htmlFor='text'><b>Funder / Payer</b><i style={{fontSize: '12px'}}> *</i><br/></label>
                    <select className="minimal" id="names" style = {{height:'40px'}} value={payer} onChange={(e) => setPayer(e.target.value)}>
                        {payerOptions.map((option, id) => <option key={id}>{option}</option>)}
                    </select>
                </div>
            </div>
            <div className='form-control-small'>
                <div className='form-control'>
                    <label htmlFor='amount'><b>Amount</b><i style={{fontSize: '12px'}}> * (negative - expense, positive - income)</i></label>
                    <input type='number' value={amount} onChange={(e) => setAmount(e.target.value)}
                    placeholder='Enter amount...' />
                </div>
                <div className='form-control'>
                    <label htmlFor='text'><b>Document</b><i style={{fontSize: '12px'}}> (invoice, bill, receipt or screenshot)</i></label>
                    <input type='text' value={doc} onChange={(e) => setDoc(e.target.value)}
                    placeholder='Enter a link...' />
                </div>
            </div>
            <div className='form-control-small'>
                <input style={{width: '5%', margin: '0 4px'}} type='checkbox' name='privacy-button' checked={privacy} onChange={(e) => setPrivacy(e.target.checked)}></input>
                <label htmlFor='privacy-button' style={{width: '45%', margin: '8px 4px'}}>
                    Make it private<br/><i style={{fontSize: '12px'}}>(hidden from team ledger)</i>
                </label>
                <button className='btn' type='submit' style={{width: '52%'}}>Record transaction</button>
            </div>
        </form>
        </div>
    )
}

export default AddTransation
