export default (state, action) => {
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    switch(action.type) {
        case 'FIND_USER':
            return {
                ...state,
                loading: false,
                loggedInUser: action.payload
            }
        case 'GET_USERS':
            return {
                ...state,
                loading: false,
                users: action.payload.filter(user => (user.org === userObj.org))
            }
        case 'GET_TRANSACTIONS':
            return {
                ...state,
                loading: false,
                transactions: action.payload.filter(transaction => (transaction.userOrg === userObj.org)).reverse()
            }
        case 'DELETE_TRANSACTION':
            return {
                ...state,
                transactions: state.transactions.filter(transaction => (transaction._id !== action.payload))
            }
        case 'ADD_TRANSACTION':
            return {
                ...state,
                transactions: [action.payload, ...state.transactions]
            }
        case 'TRANSACTION_ERROR':
            return {
                ...state,
                error: action.payload
            }
        case 'GET_QUOTES':
            return {
                ...state,
                loading: false,
                quotes: action.payload
            }
        default:
            return state;
    }
}