import React, { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalState';
import { timeFormatted } from '../../../utils/DateFormat';

import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedIcon from '@mui/icons-material/Verified';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Skeleton } from '@mui/material';

const ProfileSummary = () => {
    const { loggedInUser } = useContext(GlobalContext);

  return (
    <div className = 'photo-background'>
          { loggedInUser.firstName ? (
            <>
              <img src="https://img.icons8.com/bubbles/100/000000/user.png" alt="User-Profile" style={{width: '120px'}} />
              <h4>{loggedInUser?.firstName} {loggedInUser?.lastName}</h4>
              <h5 style={{ margin: '0.5rem' }}>
                <AssignmentIndIcon className='summary-icon-container'/> {loggedInUser?.workInfo?.designation} | {loggedInUser?.workInfo?.role}
                <br/><VerifiedIcon className='summary-icon-container'/> {timeFormatted(loggedInUser?.workInfo?.anniversary)}
                <br/><WorkIcon className='summary-icon-container'/> {loggedInUser?.org}
                <br/><BusinessIcon className='summary-icon-container'/> Company Location
              </h5>
            </>
            ) : (<Skeleton variant="rectangular" height={240} />)
          }
    </div>
  )
}

export default ProfileSummary
