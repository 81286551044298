import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { numberWithCommas } from '../../utils/NumberFormat';
import { timeFormatted } from '../../utils/DateFormat';

const Transaction = ({ transaction, isAdmin }) => {
    
    const { deleteTransaction } = useContext(GlobalContext);

    const sign = transaction.amount < 0 ? '-' : '+';

    let liColor;
    switch(transaction.privacy) {
        case true:
            transaction.amount < 0 ? liColor = 'private-minus' : liColor = 'private-plus'
            break;
        default:
            transaction.amount < 0 ? liColor = 'minus' : liColor = 'plus'
      }

    return (
        <li className={liColor}>
            <span className='record-item'>{transaction.text}</span>
            <span className='category-item'>{transaction.category}</span>
            <span className='payer-item'>{transaction.payer}</span>
            <span className='date-item'>{timeFormatted(transaction.createdAt)}</span>
            <span className='amount-item'>{sign}{numberWithCommas(Math.abs(transaction.amount))}</span>
            <button onClick={() => { deleteTransaction(transaction._id); alert('Transaction deleted!') }} className='delete-btn'>&#10005;</button>
            {/* isAdmin === 'admin' ? () => deleteTransaction(transaction._id) : console.log('User cannot delete transaction') */}
            {/* <button className='edit-btn' title='this function is under construction'>&#9998;</button> */}
        </li>
    )
}

export default Transaction
