import React from 'react';
import { timeFormatted } from '../../utils/DateFormat';

const LunchOrderedItem = ({lunch}) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  return (
    <div>
      <h4>Ordered on {timeFormatted(lunch.createdAt)}</h4>
      <div className='lunch-order-container'>
        <span style={currentDay === 1 ? {color: '#416129', textTransform: 'uppercase'} : {fontStyle: 'italic'}}>Mon: {lunch.mon}</span>
        <span style={currentDay === 2 ? {color: '#416129', textTransform: 'uppercase'} : {fontStyle: 'italic'}}>Tue: {lunch.tue}</span>
        <span style={currentDay === 3 ? {color: '#416129', textTransform: 'uppercase'} : {fontStyle: 'italic'}}>Wed: {lunch.wed}</span>
        <span style={currentDay === 4 ? {color: '#416129', textTransform: 'uppercase'} : {fontStyle: 'italic'}}>Thu: {lunch.thu}</span>
        <span style={currentDay === 5 ? {color: '#416129', textTransform: 'uppercase'} : {fontStyle: 'italic'}}>Fri: {lunch.fri}</span>
        <span style={currentDay === 6 ? {color: '#416129', textTransform: 'uppercase'} : {fontStyle: 'italic'}}>Sat: {lunch.sat}</span>
      </div>
    </div>
  )
}

export default LunchOrderedItem
