import React from 'react';

const LunchByDay = ({ menu }) => {
    return (
        <div className='lunch-menu' style={{display: 'flex', justifyContent: 'space-between'}}>
        <ul>
            <li style={{height: '4rem', padding: '0.8rem', color: '#416129', textTransform: 'uppercase', backgroundColor: '#D0D9CD'}}>
                <b>Monday<br/>({menu.monLunchMenu.mon})</b>
            </li>
            <li>{menu.monLunchMenu.dishMon1}</li>
            <li>{menu.monLunchMenu.dishMon2}</li>
            <li>{menu.monLunchMenu.dishMon3}</li>
            <li>{menu.monLunchMenu.dishMon4}</li>
            <li>{menu.monLunchMenu.dishMon5}</li>
            <li>{menu.monLunchMenu.dishMon6}</li>
            <li>{menu.monLunchMenu.dishMon7}</li>
        </ul>
        <ul>
            <li style={{height: '4rem', padding: '0.8rem', color: '#416129', textTransform: 'uppercase', backgroundColor: '#D0D9CD'}}>
                <b>Tuesday<br/>({menu.tueLunchMenu.tue})</b>
            </li>
            <li>{menu.tueLunchMenu.dishTue1}</li>
            <li>{menu.tueLunchMenu.dishTue2}</li>
            <li>{menu.tueLunchMenu.dishTue3}</li>
            <li>{menu.tueLunchMenu.dishTue4}</li>
            <li>{menu.tueLunchMenu.dishTue5}</li>
            <li>{menu.tueLunchMenu.dishTue6}</li>
            <li>{menu.tueLunchMenu.dishTue7}</li>
        </ul>
        <ul>
            <li style={{height: '4rem', padding: '0.8rem', color: '#416129', textTransform: 'uppercase', backgroundColor: '#D0D9CD'}}>
                <b>Wednesday<br/>({menu.wedLunchMenu.wed})</b>
            </li>
            <li>{menu.wedLunchMenu.dishWed1}</li>
            <li>{menu.wedLunchMenu.dishWed2}</li>
            <li>{menu.wedLunchMenu.dishWed3}</li>
            <li>{menu.wedLunchMenu.dishWed4}</li>
            <li>{menu.wedLunchMenu.dishWed5}</li>
            <li>{menu.wedLunchMenu.dishWed6}</li>
            <li>{menu.wedLunchMenu.dishWed7}</li>
        </ul>
        <ul>
            <li style={{height: '4rem', padding: '0.8rem', color: '#416129', textTransform: 'uppercase', backgroundColor: '#D0D9CD'}}>
                <b>Thursday<br/>({menu.thuLunchMenu.thu})</b>
            </li>
            <li>{menu.thuLunchMenu.dishThu1}</li>
            <li>{menu.thuLunchMenu.dishThu2}</li>
            <li>{menu.thuLunchMenu.dishThu3}</li>
            <li>{menu.thuLunchMenu.dishThu4}</li>
            <li>{menu.thuLunchMenu.dishThu5}</li>
            <li>{menu.thuLunchMenu.dishThu6}</li>
            <li>{menu.thuLunchMenu.dishThu7}</li>
        </ul>
        <ul>
            <li style={{height: '4rem', padding: '0.8rem', color: '#416129', textTransform: 'uppercase', backgroundColor: '#D0D9CD'}}>
                <b>Friday<br/>({menu.friLunchMenu.fri})</b>
            </li>
            <li>{menu.friLunchMenu.dishFri1}</li>
            <li>{menu.friLunchMenu.dishFri2}</li>
            <li>{menu.friLunchMenu.dishFri3}</li>
            <li>{menu.friLunchMenu.dishFri4}</li>
            <li>{menu.friLunchMenu.dishFri5}</li>
            <li>{menu.friLunchMenu.dishFri6}</li>
            <li>{menu.friLunchMenu.dishFri7}</li>
        </ul>
        <ul>
            <li style={{height: '4rem', padding: '0.8rem', color: '#416129', textTransform: 'uppercase', backgroundColor: '#D0D9CD'}}>
                <b>Saturday<br/>({menu.satLunchMenu.sat})</b>
            </li>
            <li>{menu.satLunchMenu.dishSat1}</li>
            <li>{menu.satLunchMenu.dishSat2}</li>
            <li>{menu.satLunchMenu.dishSat3}</li>
            <li>{menu.satLunchMenu.dishSat4}</li>
            <li>{menu.satLunchMenu.dishSat5}</li>
            <li>{menu.satLunchMenu.dishSat6}</li>
            <li>{menu.satLunchMenu.dishSat7}</li>
        </ul>
        </div>
    )
}

export default LunchByDay