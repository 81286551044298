import React from 'react';
import '../../../App.css';
import Header from '../../Header';
import { GlobalProvider } from '../../../context/GlobalState';
import HabitCalendar from '../habit-tracker/HabitCalendar';

function HabitTracker() {
  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);
  
  return (
    <GlobalProvider>
        <Header />
        <HabitCalendar user={userObj}/>
    </GlobalProvider>
  )
}

export default HabitTracker
