import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { numberWithCommas } from '../../utils/NumberFormat';

const IncomeExpenses = () => {

    const { transactions } = useContext(GlobalContext);
    const dataShared = transactions.filter(item => item.privacy !== true);

    const amounts = dataShared.map(transaction => transaction.amount);
    const income = amounts.filter(item => item > 0).reduce((acc, item) => (acc += item), 0);
    const expense = amounts.filter(item => item < 0).reduce((acc, item) => (acc -= item), 0);

    return (
        <div className='inc-exp-container'>
            <div>
                <h4>Funding</h4>
                <p className='money plus'>{income === 0 ? '' : '+'}{numberWithCommas(income)}</p>
            </div>
            <div>
                <h4>Spending</h4>
                <p className='money minus'>{expense === 0 ? '' : '-'}{numberWithCommas(expense)}</p>
            </div>
        </div>
    )
}

export default IncomeExpenses
