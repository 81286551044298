import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalState';
import { timeFormatted } from '../../../utils/DateFormat';
import ProfileEdit from './ProfileEdit';

import CakeIcon from '@mui/icons-material/Cake';
import PlaceIcon from '@mui/icons-material/Place';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import EventIcon from '@mui/icons-material/Event';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Filter2OutlinedIcon from '@mui/icons-material/Filter2Outlined';
import Looks3Icon from '@mui/icons-material/Looks3';
import Filter3OutlinedIcon from '@mui/icons-material/Filter3Outlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ExtensionIcon from '@mui/icons-material/Extension';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Skeleton } from '@mui/material';

const ProfileMain = () => {
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);

    const { loggedInUser, getUserById } = useContext(GlobalContext);

    useEffect(() => {
        getUserById(userObj._id);
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                <b style={{fontSize: '2rem', color: '#416129'}}>
                    {
                        loggedInUser.firstName ? (
                            loggedInUser?.firstName + " " + loggedInUser?.lastName
                        ) : ( <Skeleton variant="rectangular" />)
                    }
                </b>
                <ProfileEdit />
            </div>
            <br/>
            <div className='profile-section'>
                <h3 style={{marginTop: '1rem'}}>Contact</h3>
                <div className='profile-part'>
                    <PhoneIphoneIcon className='icon-container'/>
                    <span className='profile-span-50'>
                        Mobile 
                        <b>
                            {
                                loggedInUser?.contact?.mobile ? (
                                    loggedInUser?.contact?.mobile
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <MailIcon className='icon-container'/>
                    <span className='profile-span-50'>
                        Work Email 
                        <b>
                            {
                                loggedInUser?.email ? (
                                    loggedInUser?.email
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ContactMailIcon className='icon-container'/>
                    <span className='profile-span-50'>
                        Personal Email 
                        <b>
                            {
                                loggedInUser?.contact?.personalEmail ? (
                                    loggedInUser?.contact?.personalEmail
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
            <div className='profile-section'>
            <h3 style={{marginTop: '1rem'}}>Work Information</h3>
                <div className='profile-part'>
                    <AssignmentIndIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Designation 
                        <b>
                            {
                                loggedInUser?.workInfo?.designation ? (
                                    loggedInUser?.workInfo?.designation
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ExtensionIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Role in Team 
                        <b>
                            {
                                loggedInUser?.workInfo?.role ? (
                                    loggedInUser?.workInfo?.role
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <GroupIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Team 
                        <b>
                            {
                                loggedInUser?.team ? (
                                    loggedInUser?.team
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <GroupsIcon className='icon-container'/>
                    <span className='profile-span-25'>
                        Department 
                        <b>
                            {
                                loggedInUser?.workInfo?.department ? (
                                    loggedInUser?.workInfo?.department
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
            <div className='profile-section'>
                <h3 style={{marginTop: '1rem'}}>Information For Contract</h3>
                <div className='profile-part'>
                    <CakeIcon className='icon-container'/>
                    <span className='profile-span-33'>
                        Date of birth 
                        <b>
                            {
                                loggedInUser?.personalInfo?.dob ? (
                                    timeFormatted(loggedInUser?.personalInfo?.dob)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <PersonPinCircleIcon className='icon-container'/>
                    <span className='profile-span-70'>
                        Current Address 
                        <b>
                            {
                                loggedInUser?.personalInfo?.currentAddress ? (
                                    loggedInUser?.personalInfo?.currentAddress
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <RecentActorsIcon className='icon-container'/>
                    <span className='profile-span-33'>
                        ID Card Number 
                        <b>
                            {
                                loggedInUser?.personalInfo?.idCardNo ? (
                                    loggedInUser?.personalInfo?.idCardNo
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <PlaceIcon className='icon-container'/>
                    <span className='profile-span-70'>
                        Address on ID card 
                        <b>
                            {
                                loggedInUser?.personalInfo?.idCardAddress ? (
                                    loggedInUser?.personalInfo?.idCardAddress
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
            <div className='profile-section'>
                <h3 style={{marginTop: '1rem'}}>Contract Milestones</h3>
                <div className='profile-part'>
                    <CalendarTodayIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Internship Start 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.internship?.startDate ? (
                                    timeFormatted(loggedInUser?.contractMilestones?.internship?.startDate)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LocalLibraryIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Internship Contract 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.internship?.internshipContract ? (
                                    loggedInUser?.contractMilestones?.internship?.internshipContract
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LocalLibraryOutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.internship?.internshipAnnex1 ? (
                                    loggedInUser?.contractMilestones?.internship?.internshipAnnex1
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <DateRangeIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Collaboration Start 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.collaboration?.collaborationDate ? (
                                    timeFormatted(loggedInUser?.contractMilestones?.collaboration?.collaborationDate)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <HandshakeIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Collaboration Contract 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.collaboration?.collaborationContract ? (
                                    loggedInUser?.contractMilestones?.collaboration?.collaborationContract
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <HandshakeOutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.collaboration?.collaborationAnnex1 ? (
                                    loggedInUser?.contractMilestones?.collaboration?.collaborationAnnex1
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Probation Start 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.probation?.probationDate ? (
                                    timeFormatted(loggedInUser?.contractMilestones?.probation?.probationDate)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ConfirmationNumberIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Probation Contract 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.probation?.probationContract ? (
                                    loggedInUser?.contractMilestones?.probation?.probationContract
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <ConfirmationNumberOutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.probation?.probationAnnex1 ? (
                                    loggedInUser?.contractMilestones?.probation?.probationAnnex1
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventNoteIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Official Employment Date 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract1?.contractDate1 ? (
                                    timeFormatted(loggedInUser?.contractMilestones?.empContract1?.contractDate1)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LooksOneIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Contract 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract1?.contract1No ? (
                                    loggedInUser?.contractMilestones?.empContract1?.contract1No
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Filter1OutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract1?.annex11 ? (
                                    loggedInUser?.contractMilestones?.empContract1?.annex11
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventRepeatIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Renewal Date 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract2?.contractDate2 ? (
                                    timeFormatted(loggedInUser?.contractMilestones?.empContract2?.contractDate2)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <LooksTwoIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Contract 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract2?.contract2No ? (
                                    loggedInUser?.contractMilestones?.empContract2?.contract2No
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Filter2OutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract2?.annex21 ? (
                                    loggedInUser?.contractMilestones?.empContract2?.annex21
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
                <div className='profile-part'>
                    <EventRepeatIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Renewal Date 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract3?.contractDate3 ? (
                                    timeFormatted(loggedInUser?.contractMilestones?.empContract3?.contractDate3)
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Looks3Icon className='icon-container'/>
                    <span className='profile-span-30'>
                        Employment Contract 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract3?.contract3No ? (
                                    loggedInUser?.contractMilestones?.empContract3?.contract3No
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                    <Filter3OutlinedIcon className='icon-container'/>
                    <span className='profile-span-30'>
                        Appendix 
                        <b>
                            {
                                loggedInUser?.contractMilestones?.empContract3?.annex31 ? (
                                    loggedInUser?.contractMilestones?.empContract3?.annex31
                                ) : ( <Skeleton variant="rectangular" width={160}/>)
                            }
                        </b>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ProfileMain
