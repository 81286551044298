import React from 'react'

function Logout() {
    
    function logout (e) {
        window.localStorage.clear();
        window.location.reload(false);
        // alert('You have signed out. Please log in again.');
    }
  
    return (
        <div className='logout'>
            <button className="logout-btn" title='Logout' onClick={logout}>&#128272;</button>
        </div>
    )
}

export default Logout
