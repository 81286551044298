import { React, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { numberWithCommas } from '../../utils/NumberFormat';
import { timeFormatted } from '../../utils/DateFormat';


function List(props) {
    const { transactions } = useContext(GlobalContext);

    const searchedData = transactions.filter((el) => {
        if (props.input === '') {
            return "";
        } else if (timeFormatted(el.createdAt).includes(props.input)) {
            return timeFormatted(el.createdAt).includes(props.input)
        } else if (el.payer.toLowerCase().includes(props.input)) {
            return el.payer.toLowerCase().includes(props.input)
        } else if (el.category.toLowerCase().includes(props.input)) {
            return el.category.toLowerCase().includes(props.input)
        } else {
            return el.text.toLowerCase().includes(props.input)
        }
    })

    return (
            <ul className='list' style={{display: 'flex', flexDirection: 'column', alignItems: 'end', margin: '5px 0'}}>
                {searchedData.map((item) => (
                    <li id='search-item' key={item._id} className={item.amount < 0 ? 'minus' : 'plus'}>
                        <span style={{width: '70%'}}><b>{item.text}</b>
                            <br/><i>{timeFormatted(item.createdAt)}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{item.payer}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{item.category}</i>
                        </span>
                        <span className='amount-item'>{item.amount < 0 ? '' : '+'} <b>{numberWithCommas(item.amount)}</b></span>
                    </li>
                ))}
            </ul>
    )
}

export default List