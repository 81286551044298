import React, { createContext, useReducer } from 'react';
import ServiceReducer from './ServiceReducer';
import axios from 'axios';

const initialState = {
    lunchMenus: [],
    lunchOrders: [],
    error: null,
    loading: true
};

export const ServiceContext = createContext(initialState);

export const ServiceProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ServiceReducer, initialState);

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const token = `${userObj.email}+${userObj.password}`    

    async function getLunchMenu() {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            const res = await axios.get('/api/v1/lunch-menu', config);

            dispatch({
                type: 'GET_LUNCH_MENUS',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'LUNCH_MENUS_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function addLunchMenu(menu) {   
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/v1/lunch-menu', menu, config);

            dispatch({
                type: 'ADD_LUNCH_MENUS',
                payload: res.data.data
            });

        } catch (err) {
            dispatch({
                type: 'LUNCH_MENUS_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function getLunchOrder() {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            const res = await axios.get('/api/v1/lunch-order', config);

            dispatch({
                type: 'GET_LUNCH_ORDERS',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'LUNCH_MENUS_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function orderLunch(order) {   
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/v1/lunch-order', order, config);

            dispatch({
                type: 'ORDER_LUNCH',
                payload: res.data.data
            });

        } catch (err) {
            dispatch({
                type: 'ORDER_LUNCH_ERROR',
                payload: err.response.data.error
            });
        }
    }

    return (<ServiceContext.Provider value={{
        lunchMenus: state.lunchMenus,
        getLunchMenu,
        addLunchMenu,
        lunchOrders: state.lunchOrders,
        getLunchOrder,
        orderLunch
    }}>
        {children}
    </ServiceContext.Provider>)
}