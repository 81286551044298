import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalState';

import CakeIcon from '@mui/icons-material/Cake';
import PlaceIcon from '@mui/icons-material/Place';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ProfileMainEditByAdmin from './ProfileMainEditByAdmin';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const ProfileMainEdit = ({state, setState}) => {

    const { loggedInUser } = useContext(GlobalContext);

    useEffect(() => {
        setState(loggedInUser);
        // eslint-disable-next-line
    }, []);
    
    const formatDate = (str) => {
        return str.slice(0, 10)
    }

    const [ contact, setContact ] = useState(loggedInUser?.contact);
    const [ personalInfo, setPersonalInfo ] = useState(loggedInUser?.personalInfo);

    return (
        <form>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                <b style={{fontSize: '2rem'}}>{loggedInUser?.firstName} {loggedInUser?.lastName}</b>
            </div>
            <br/>
            <h3 style={{marginTop: '1rem', width: '100%'}}>Contact</h3>
            <div className='profile-part'>
                <PhoneIphoneIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Mobile
                    <input type='text' id='mobile' value={contact?.mobile} onChange={(e) => {
                                    setContact(e.target.value);
                                    setState({...state, contact: { ...state.contact, mobile: e.target.value}})
                                }} 
                        placeholder={contact?.mobile} />
                </span>
                <ContactMailIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Personal Email
                    <input type='text' id='mobile' value={contact?.personalEmail} onChange={(e) => {
                                    setContact(e.target.value);
                                    setState({...state, contact: { ...state.contact, personalEmail: e.target.value}})
                                }} 
                        placeholder={contact?.personalEmail} />
                </span>
            </div>
            <br/>
            <h3 style={{marginTop: '1rem', width: '100%'}}>Information for Contract</h3>
            <div className='profile-part'>
                <CakeIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Date of birth
                    <br/>
                    <input type='date' id='dob' defaultValue={formatDate(personalInfo?.dob)}
                        onChange={(e) => {
                            setPersonalInfo({...personalInfo, dob: e.target.value});
                            setState({...state, personalInfo: {...state.personalInfo, dob: e.target.value}})
                            }} />
                </span>
                <PersonPinCircleIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Current Address
                    <input type='text' id='current-address' value={personalInfo?.currentAddress}
                        onChange={(e) => {
                            setPersonalInfo({...personalInfo, currentAddress: e.target.value});
                            setState({...state, personalInfo: {...state.personalInfo, currentAddress: e.target.value}})
                        }} 
                        placeholder={personalInfo?.currentAddress} />
                </span>
            </div>
            <div className='profile-part'>
                <RecentActorsIcon className='icon-container'/>
                <span className='profile-span-50'>
                    ID Card Number
                    <input type='text' id='id-card-no'  value={personalInfo?.idCardNo}
                        onChange={(e) => {
                            setPersonalInfo({...personalInfo, idCardNo: e.target.value});
                            setState({...state, personalInfo: {...state.personalInfo, idCardNo: e.target.value}})
                        }} 
                        placeholder={personalInfo?.idCardNo} />
                </span>
                <PlaceIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Address on ID card
                    <input type='text' id='id-card-address'   value={personalInfo?.idCardAddress}
                        onChange={(e) => {
                            setPersonalInfo({...personalInfo, idCardAddress: e.target.value})
                            setState({...state, personalInfo: {...state.personalInfo, idCardAddress: e.target.value}})
                        }} 
                        placeholder={personalInfo?.idCardAddress} />
                </span>
            </div>
            <div className='profile-part'>
                <CalendarMonthIcon className='icon-container'/>
                <span className='profile-span-50'>
                    ID Card Issuance Date
                    <input type='date' id='id-card-date'  defaultValue={formatDate(personalInfo?.idCardDate)}
                        onChange={(e) => {
                            setPersonalInfo({...personalInfo, idCardDate: e.target.value})
                            setState({...state, personalInfo: {...state.personalInfo, idCardDate: e.target.value}})
                        }} 
                        placeholder={personalInfo?.idCardDate} />
                </span>
                <LocationCityIcon className='icon-container'/>
                <span className='profile-span-50'>
                    Hometown on ID card
                    <input type='text' id='id-card-hometown'   value={personalInfo?.idCardLocation}
                        onChange={(e) => {
                            setPersonalInfo({...personalInfo, idCardLocation: e.target.value})
                            setState({...state, personalInfo: {...state.personalInfo, idCardLocation: e.target.value}})
                        }} 
                        placeholder={personalInfo?.idCardLocation} />
                </span>
            </div>
            <br/>
            {loggedInUser.type === 'user' ? <br/> : <ProfileMainEditByAdmin user={loggedInUser} state={state} setState={setState} />}
        </div>
        </form>
    )
}

export default ProfileMainEdit