import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import axios from 'axios';
import { io } from 'socket.io-client';

const socket = io('http://localhost:3001')

const initialState = {
    loggedInUser: [],
    users: [],
    transactions: [],
    quotes: [],
    error: null,
    loading: true
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const token = `${userObj.email}+${userObj.password}`

    async function getUserById(id) {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        try {
            const res = await axios.get(`/api/v1/users/profile/${id}`, config);

            dispatch({
                type: 'FIND_USER',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'USER_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function getUsers() {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            const res = await axios.get('/api/v1/users', config);

            dispatch({
                type: 'GET_USERS',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'USER_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function editUser(id, editedUser) {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/v1/users/${id}`, editedUser, config);
            dispatch({
                type: 'EDIT_USER',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'USER_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function getTransactions() {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            const res = await axios.get('/api/v1/transactions', config);

            dispatch({
                type: 'GET_TRANSACTIONS',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'TRANSACTION_ERROR',
                payload: err.response.data.error
            });
        }
    }
    
    async function deleteTransaction(id) {
        try {
            await axios.delete(`/api/v1/transactions/${id}`);
            dispatch({
                type: 'DELETE_TRANSACTION',
                payload: id
            });
            socket.emit('add-transaction', '')
        } catch (err) {
            dispatch({
                type: 'TRANSACTION_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function addTransaction(transaction) {   
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/v1/transactions', transaction, config);

            dispatch({
                type: 'ADD_TRANSACTION',
                payload: res.data.data
            });

        } catch (err) {
            dispatch({
                type: 'TRANSACTION_ERROR',
                payload: err.response.data.error
            });
        }
    }

    async function getQuotes() {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            const res = await axios.get('/api/v1/quotes', config);

            dispatch({
                type: 'GET_QUOTES',
                payload: res.data.data
            });
        } catch (err) {
            dispatch({
                type: 'QUOTE_ERROR',
                payload: err.response.data.error
            });
        }
    }

    return (<GlobalContext.Provider value={{
        transactions: state.transactions,
        quotes: state.quotes,
        error: state.error,
        loading: state.loading,
        loggedInUser: state.loggedInUser,
        users: state.users,
        getUserById,
        getUsers,
        editUser,
        getTransactions,
        deleteTransaction,
        addTransaction,
        getQuotes
    }}>
        {children}
    </GlobalContext.Provider>)
}