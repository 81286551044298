import React, { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../service-center/ServiceState';
import LunchByDay from './LunchByDay';
import Loader from '../../utils/Loader';

const LunchList = () => {

    const { lunchMenus, getLunchMenu, orderLunch } = useContext(ServiceContext);

    useEffect(() => {
        getLunchMenu();
        // eslint-disable-next-line
    }, []);

    const monLunchMenu = lunchMenus[0] ? lunchMenus.map( menu => menu.monLunchMenu) : [];
    const monLunches = monLunchMenu[0] ? [
        'None',
        monLunchMenu[0].dishMon1,
        monLunchMenu[0].dishMon2,
        monLunchMenu[0].dishMon3,
        monLunchMenu[0].dishMon4,
        monLunchMenu[0].dishMon5,
        monLunchMenu[0].dishMon6,
        monLunchMenu[0].dishMon7,
    ] : [];
        
    const tueLunchMenu = lunchMenus[0] ? lunchMenus.map( menu => menu.tueLunchMenu) : [];
    const tueLunches = tueLunchMenu[0] ? [
        'None',
        tueLunchMenu[0].dishTue1,
        tueLunchMenu[0].dishTue2,
        tueLunchMenu[0].dishTue3,
        tueLunchMenu[0].dishTue4,
        tueLunchMenu[0].dishTue5,
        tueLunchMenu[0].dishTue6,
        tueLunchMenu[0].dishTue7,
    ] : [];

    const wedLunchMenu = lunchMenus[0] ? lunchMenus.map( menu => menu.wedLunchMenu) : [];
    const wedLunches = wedLunchMenu[0] ? [
        'None',
        wedLunchMenu[0].dishWed1,
        wedLunchMenu[0].dishWed2,
        wedLunchMenu[0].dishWed3,
        wedLunchMenu[0].dishWed4,
        wedLunchMenu[0].dishWed5,
        wedLunchMenu[0].dishWed6,
        wedLunchMenu[0].dishWed7,
    ] : [];

    const thuLunchMenu = lunchMenus[0] ? lunchMenus.map( menu => menu.thuLunchMenu) : [];
    const thuLunches = thuLunchMenu[0] ? [
        'None',
        thuLunchMenu[0].dishThu1,
        thuLunchMenu[0].dishThu2,
        thuLunchMenu[0].dishThu3,
        thuLunchMenu[0].dishThu4,
        thuLunchMenu[0].dishThu5,
        thuLunchMenu[0].dishThu6,
        thuLunchMenu[0].dishThu7,
    ] : [];

    const friLunchMenu = lunchMenus[0] ? lunchMenus.map( menu => menu.friLunchMenu) : [];
    const friLunches = friLunchMenu[0] ? [
        'None',
        friLunchMenu[0].dishFri1,
        friLunchMenu[0].dishFri2,
        friLunchMenu[0].dishFri3,
        friLunchMenu[0].dishFri4,
        friLunchMenu[0].dishFri5,
        friLunchMenu[0].dishFri6,
        friLunchMenu[0].dishFri7,
    ] : [];

    const satLunchMenu = lunchMenus[0] ? lunchMenus.map( menu => menu.satLunchMenu) : [];
    const satLunches = satLunchMenu[0] ? [
        'None',
        satLunchMenu[0].dishSat1,
        satLunchMenu[0].dishSat2,
        satLunchMenu[0].dishSat3,
        satLunchMenu[0].dishSat4,
        satLunchMenu[0].dishSat5,
        satLunchMenu[0].dishSat6,
        satLunchMenu[0].dishSat7,
    ] : [];


    const [ order, setOrder ] = useState({});
    const { mon, tue, wed, thu, fri, sat } = order;

    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);

    const onSubmit = e => {
        e.preventDefault();

        const newOrder = {
            id: Math.floor(Math.random() * 100000000),
            customer: `${userObj.firstName} ${userObj.lastName}`,
            customerId: userObj._id,
            customerOrg: userObj.org,
            customerEmail: userObj.email,
            mon,
            tue,
            wed,
            thu,
            fri,
            sat
            
        }

        orderLunch(newOrder);
        alert("Your order has been recorded! Thank you!");

    }

    return (
        <div>
            <h3 style={{width: '100%'}}>Lunch Picker</h3>
            <form onSubmit={onSubmit}>
                <div className='lunch-form-control-small'>
                    <label htmlFor='mon-lunch' style={{width: '7%'}}><b>Monday</b></label>
                    <select className="minimal" id="mon-lunch" style = {{height:'40px', width: '23%'}} value={order.mon} onChange={(e) => setOrder({...order, mon: e.target.value})}>
                        {monLunches.map(option => <option key={Math.floor(Math.random() * 100000000)}>{option}</option>)}
                    </select>
                    <label htmlFor='tue-lunch' style={{width: '7%'}}><b>Tuesday</b></label>
                    <select className="minimal" id="tue-lunch" style = {{height:'40px', width: '23%'}} value={order.tue} onChange={(e) => setOrder({...order, tue: e.target.value})}>
                        {tueLunches.map(option => <option key={Math.floor(Math.random() * 100000000)}>{option}</option>)}
                    </select>
                    <label htmlFor='tue-lunch' style={{width: '7%'}}><b>Wednesday</b></label>
                    <select className="minimal" id="tue-lunch" style = {{height:'40px', width: '23%'}} value={order.wed} onChange={(e) => setOrder({...order, wed: e.target.value})}>
                        {wedLunches.map(option => <option key={Math.floor(Math.random() * 100000000)}>{option}</option>)}
                    </select>
                </div>
                <div className='lunch-form-control-small'>
                    <label htmlFor='mon-lunch' style={{width: '7%'}}><b>Thursday</b></label>
                    <select className="minimal" id="mon-lunch" style = {{height:'40px', width: '23%'}} value={order.thu} onChange={(e) => setOrder({...order, thu: e.target.value})}>
                        {thuLunches.map(option => <option key={Math.floor(Math.random() * 100000000)}>{option}</option>)}
                    </select>
                    <label htmlFor='tue-lunch' style={{width: '7%'}}><b>Friday</b></label>
                    <select className="minimal" id="tue-lunch" style = {{height:'40px', width: '23%'}} value={order.fri} onChange={(e) => setOrder({...order, fri: e.target.value})}>
                        {friLunches.map(option => <option key={Math.floor(Math.random() * 100000000)}>{option}</option>)}
                    </select>
                    <label htmlFor='tue-lunch' style={{width: '7%'}}><b>Saturday</b></label>
                    <select className="minimal" id="tue-lunch" style = {{height:'40px', width: '23%'}} value={order.sat} onChange={(e) => setOrder({...order, sat: e.target.value})}>
                        {satLunches.map(option => <option key={Math.floor(Math.random() * 100000000)}>{option}</option>)}
                    </select>
                </div>
                <button className='lunch-btn' type='submit'>Submit Your Order</button>
            </form>
            <br/>
            <h3 style={{width: '100%'}}>Lunch Menu</h3>
            {monLunchMenu[0] ? <div>{lunchMenus.map(menu => <LunchByDay key={menu._id} menu={menu} />)}</div> : 
                <div><Loader /><br/><i>It seems this service has not been enabled yet. Please contact your admin.</i></div>}
        </div>
    )
}

export default LunchList
