import React from 'react';
import { numberWithCommas } from '../../utils/NumberFormat';
import { getMonthName } from '../../utils/MonthName';


const MonthlySummaryItem = ({item}) => {

    return (
        <div>
                <li style={{borderRadius: '6px'}}>
                    <span style={{width: '6rem', textAlign: "center", fontSize: "14px"}} className='money'>
                        {getMonthName(item.month)}</span>
                    <span style={{width: '6rem', textAlign: "right", fontSize: "14px"}} className='money'>
                        {item.monthIncome === 0 ? '' : '+'}{numberWithCommas(Math.abs(item.monthIncome))}</span>
                    <span style={{width: '6rem', textAlign: "right", fontSize: "14px"}} className='money'>
                        {item.monthExpense === 0 ? '' : '-'}{numberWithCommas(Math.abs(item.monthExpense))}</span>
                    <span style={{width: '6rem', textAlign: "right", fontSize: "14px"}} className={item.monthIncome < item.monthExpense ? 'money minus' : 'money plus'}>
                        {numberWithCommas(item.monthIncome-item.monthExpense)}</span>
                </li>
        </div>
  )
}

export default MonthlySummaryItem
