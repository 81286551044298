import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import Transaction from './Transaction';
import { io } from 'socket.io-client';

const socket = io('http://localhost:3001');

const TransactionList = ({isAdmin}) => {

    const { transactions, getTransactions } = useContext(GlobalContext);
    const dataShared = transactions.filter(item => item.privacy !== true);

    useEffect(() => {
        getTransactions();
        // eslint-disable-next-line
    }, []);

    socket.on('sync-transaction', () => {
        getTransactions();
    });

    const [filterChecked, setRadio] = useState('all');
    const filteredData = dataShared.filter((el) => {
        if (filterChecked === 'income') {
            return el.amount > 0;
        } else if (filterChecked === 'expense') {
            return el.amount < 0;
        }
    });

    return (
        <div>
            <h3>History
                <div className='filter-buttons'>
                    <input type='radio' id='filter-all' name='filter' value='all' onClick={(e)=>{setRadio(e.target.value)}}></input>
                    <label htmlFor='filter-all'>All Records</label>
                    <input type='radio' id='filter-income' name='filter' value='income' onClick={(e)=>{setRadio(e.target.value)}}></input>
                    <label htmlFor='filter-income'>Income</label>
                    <input type='radio' id='filter-expense' name='filter' value='expense' onClick={(e)=>{setRadio(e.target.value)}}></input>
                    <label htmlFor='filter-expense'>Expense</label>
                </div>
            </h3>
            <div className='list-heading'>
                <span className='record-item'>Transaction <i style={{fontSize: '12px'}}>(Scroll down for more)</i></span>
                <span className='category-item'>Category</span>
                <span className='payer-item'>Payer</span>
                <span className='date-item'>Date</span>
                <span className='amount-item'>Amount</span>
            </div>
            <ul className='list' id='scroll-list'>
                {filterChecked === 'all' ? dataShared.map(transaction => (<Transaction key={transaction._id} transaction={transaction} isAdmin={isAdmin} />)) : filteredData.map(transaction => (<Transaction key={transaction._id} transaction={transaction} />)) }   
            </ul>
        </div>
    )
}

export default TransactionList
