export function timeFormatted(str) {
    let date = new Date(str);
    let yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    
    const formattedDate = yyyy + '-' + mm + '-' + dd
    return formattedDate;
}