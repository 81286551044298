import React from 'react';
import Header from '../Header';
import { GlobalProvider } from '../../context/GlobalState';
import { TaskProvider } from './TaskState';
import ServiceKanban from './components/ServiceKanban';
import AddTicket from './components/AddTicket';

const ServiceBoard = () => {
  return (
    <GlobalProvider>
        <Header />
        <TaskProvider>
            <div className='accordion'>
              <details>
                <summary style={{margin: '0.7rem 1rem 1.5rem', fontSize: '1.25rem', padding: '0.3rem 0.7rem'}}>+</summary>
                <AddTicket />
              </details>
            </div>
            <ServiceKanban />
        </TaskProvider>
    </GlobalProvider>
  )
}

export default ServiceBoard
