import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { numberWithCommas } from '../../utils/NumberFormat';

const PersonalIncomeExpenses = () => {

    const { transactions } = useContext(GlobalContext);
    
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const dataByUser = transactions.filter(item => item.userId === userObj._id);
    const amounts = dataByUser.map(transaction => transaction.amount);
    const income = amounts.filter(item => item > 0).reduce((acc, item) => (acc += item), 0);
    const expense = amounts.filter(item => item < 0).reduce((acc, item) => (acc -= item), 0);

    return (
        <div className='inc-exp-container'>
            <div>
                <h4>Funding</h4>
                <p className='money plus'>{income === 0 ? '' : '+'}{numberWithCommas(income)}</p>
            </div>
            <div>
                <h4>Spending</h4>
                <p className='money minus'>{expense === 0 ? '' : '-'}{numberWithCommas(expense)}</p>
            </div>
        </div>
    )
}

export default PersonalIncomeExpenses
