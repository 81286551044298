import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import CatSummaryItem from '../expense-tracker/CatSummaryItem';

const PersonalCatSummary = () => {
    const { transactions } = useContext(GlobalContext);
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const dataByUser = transactions.filter(item => item.userId === userObj._id);

    const catArray = [];

    for (let i = 0; i < dataByUser.length; i++) {
        if (catArray.find(cat => cat === dataByUser[i].category) === undefined) {

            catArray.unshift(dataByUser[i].category)
        }
    }

    const catObjectArray = [];
    for (let i = 0; i < catArray.length; i++) {
        const catObject = {};
        catObject.category = catArray[i];
        let transactionsByCat = dataByUser.filter(transaction => transaction.category === catArray[i]);
        let expense = transactionsByCat.map(transaction => transaction.amount).filter(item => item < 0).reduce((acc, item) => (acc -= item), 0);
        catObject.expense = expense;

        catObjectArray.unshift(catObject)
    }

    return (
        <div>
            <h3 style={{border: 'none'}}>Spending Category</h3>
            <ul className='card'>
                    {catObjectArray.map((item, id) => (<CatSummaryItem key={id} item={item}/>))}
            </ul>
        </div>
    )
}

export default PersonalCatSummary
